import { api } from "../../api/endpoints";
import {
    ADD_SPLITSCREEN_SUCCESS,
    GET_SPLITSCREEN_SUCCESS,
    ADD_SPLITSCREEN_FAILED,
    DELETE_SPLITSCREEN
} from "../constant";

export const addSplitScreen = (obj) => {
    return async (dispatch) => {
        console.log("splitScreen adding=>", obj);
        try {
            console.log("POST")
            const splitScreen = await api.post(`/splitscreen/addsplitscreen`, obj);
            if (splitScreen.status === 201) {
                dispatch({
                    type: ADD_SPLITSCREEN_SUCCESS,
                    payload: splitScreen.data.splitscreen,
                });
            }
        } catch (error) {
            dispatch({
                type: ADD_SPLITSCREEN_FAILED,
                payload: error.response.data.toString(),
            });
        }
    };
};

export const getSplitScreensById = (id) => {
    return async (dispatch) => {
        const splitScreen = await api.get(`/splitscreen/getsplitscreens/${id}`);
        if (splitScreen.status === 200) {
            dispatch({
                type: GET_SPLITSCREEN_SUCCESS,
                payload: splitScreen.data.splitScreens,
            });
        }
    };
};

export const deleteSplitScreen = (ids) => {
    return async (dispatch) => {
        dispatch({
            type: DELETE_SPLITSCREEN,
            payload: ids,
        });
        const splitScreen = await api.post(`/splitscreen/deletesplitscreen`, { ids });
        console.log("delete splitScreen ");
    };
}