import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Divider, Box, TablePagination } from "@mui/material";

import { baseImg } from "../../../api/endpoints";
import ContentCard from "../../../components/contentcard/ContentCard";
import ListViewFile from "./ListViewFile";
import DialogBoxEditScreen from "./EditScreen/DialogBoxEditScreen"

function All({
  content,
  onAddPlaylist,
  onPushScreen,
  onDelete,
  onMove,
  onPreview,
  view,
  onCheckedSingleContent,
  onUncheckedSingleContent,
  onUncheckedContent,
  handleSelectAll,
}) {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openSplitScreenDialog, setOpenSplitScreenDialog] = React.useState(false)
  const [savedZones, setSavedZones] = React.useState({})

  const contentToView = [...content].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  useEffect(() => {
    if (handleSelectAll) {
      contentToView.forEach((c) => onCheckedSingleContent(c.id));
    }
  }, [handleSelectAll]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onUncheckedContent();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    onUncheckedContent();
  };

  const handleSeeSplitScreen = (contentObject) =>{
    console.log("see split", contentObject)
    setSavedZones(contentObject)
    setOpenSplitScreenDialog(true)
  }

  return (
    <>
      <DialogBoxEditScreen isOpen={openSplitScreenDialog} onClose={setOpenSplitScreenDialog} orientation={savedZones?.orientation || "portrait"} rectanglesUnfolding={savedZones} isNew={false}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          flexWrap: "wrap",
          margin: "10px 0",
        }}>
        {view
          ? contentToView.map((data, index) => {
            if (data?.type == "split_screen"){
              return (
                <div>TUTAJ SPLIT SCREEN</div>
                // <ContentCard
                //   key={++index}
                //   url={`${baseImg}/${data.url}`}
                //   type={data.type}
                //   name={data.name}
                //   checked={data?.checked}
                //   onCheckedSingleContent={onCheckedSingleContent}
                //   onUncheckedSingleContent={onUncheckedSingleContent}
                //   contentObject={data}
                //   onAddPlaylist={onAddPlaylist}
                //   onPushScreen={onPushScreen}
                //   onDelete={onDelete}
                //   onMove={onMove}
                //   onPreview={onPreview}
                //   createdAt={data.createdAt}
                // />
              );
            }
            else{
              if (data?.isActive) {
                return (
                  <ContentCard
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                  />
                );
              }
            }

            })
          : contentToView.map((data, index) => {
            if (data?.type == "split_screen" && data?.name && data?.createdAt && data?.orientation){
              return (
                // <div>TUTAJ SPLIT SCREEN</div>

                <ListViewFile
                  key={++index}
                  url={``}
                  type={data.type}
                  name={data.name || "undefined"}
                  checked={data?.checked}
                  onCheckedSingleContent={onCheckedSingleContent}
                  onUncheckedSingleContent={onUncheckedSingleContent}
                  contentObject={data}
                  onAddPlaylist={onAddPlaylist}
                  onPushScreen={onPushScreen}
                  onDelete={onDelete}
                  onMove={onMove}
                  onPreview={onPreview}
                  createdAt={data.createdAt}

                  handleSeeSplitScreen={handleSeeSplitScreen}
                />
              );
            }
            else{
              if (data?.isActive) {
                return (
                  <ListViewFile
                    key={++index}
                    url={`${baseImg}/${data.url}`}
                    type={data.type}
                    name={data.name}
                    checked={data?.checked}
                    onCheckedSingleContent={onCheckedSingleContent}
                    onUncheckedSingleContent={onUncheckedSingleContent}
                    contentObject={data}
                    onAddPlaylist={onAddPlaylist}
                    onPushScreen={onPushScreen}
                    onDelete={onDelete}
                    onMove={onMove}
                    onPreview={onPreview}
                    createdAt={data.createdAt}
                  />
                );
              }
            }
            })}
      </Box>
      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={content.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("views.all.rowsQty")}
      />
    </>
  );
}

export default All;
